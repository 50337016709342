import axios, { AxiosRequestConfig } from 'axios';
import keycloak from './Keycloak'; // Import your Keycloak instance
import { getSelectedPilot } from './pilotContext';

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor to include the token in the Authorization header
axiosInstance.interceptors.request.use(
  async (config) => {
    const selectedPilot = getSelectedPilot();
    // Ensure Keycloak is authenticated
    if (keycloak.authenticated) {
      try {
        // Update token if needed (e.g., 30 seconds before expiry)
        await keycloak.updateToken(30);

        // Attach the token to the Authorization header
        const token = keycloak.token;
        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            ...(selectedPilot && { "x-pilot": selectedPilot })
          };
        }
      } catch (error) {
        console.error('Failed to refresh Keycloak token', error);
        keycloak.logout(); // Optionally log the user out if token refresh fails
      }
    }
    return config;
  },
  (error) => {
    // Handle any error in request setup
    return Promise.reject(error);
  }
);

export default axiosInstance;
