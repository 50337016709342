export enum Metric {
    None = 0,
    AirQuality = 1,
    Temperature = 2,
    Humidity = 3,
    ThermalComfort = 4,
    AcousticComfort = 5,
    VisualComfort = 6,
    ElectricityConsumption = 555,
}

export enum Units {
    None = 0,
    PPM = 11,
    DegreesCelsius = 21,
    DegreesFarenheit = 22,
    Percent = 31,
    Decibel = 51,
    Lux = 61,
    kW = 555,
}

export enum TimeAggregationPeriod {
    None = 0,
    Day = 1,
    Weekday = 2,
    Week = 3,
    Month = 4,
    Season = 5
}

export enum WeekDays {
    None = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

export enum Months {
    None = 0,
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export enum Seasons {
    None = 0,
    Spring = 1,
    Summer = 2,
    Autumn = 3,
    Winter = 4,
}

export function UnitToPretty(units?: Units): string {
    switch (units) {
        case Units.PPM:
            return "ppm";
        case Units.DegreesCelsius:
            return "°C";
        case Units.DegreesFarenheit:
            return "°F";
        case Units.Percent:
            return "%";
        case Units.Decibel:
            return "dB"
        case Units.Lux:
            return "lux"
        case Units.kW:
            return "kW"
        default:
            return "";
    }
}

export function MetricToPretty(metric: Metric): string {
    switch (metric) {
        case Metric.AirQuality:
            return "Air Quality";
        case Metric.Temperature:
            return "Temperature";
        case Metric.Humidity:
            return "Humidity";
        case Metric.ThermalComfort:
            return "Thermal Comfort";
        case Metric.AcousticComfort:
            return "Acoustic Comfort";
        case Metric.VisualComfort:
            return "Visual Comfort";
        case Metric.ElectricityConsumption:
            return "Electricity Consumption";
        default:
            return "";
    }
}

export type MetricDescriptions = {
    [key in Metric]: string;
  };